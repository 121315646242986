.App {
    font-family: sans-serif;
    text-align: center;
  }

  .homeButton {
    background-color: #26B9C7;
    margin-left: 40%; 
    margin-right: 40%;
  }
  
  @media screen and (max-width: 600px) {
    .homeButton {
      margin-left: 20%; 
      margin-right: 20%
    }
  }
  