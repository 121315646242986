/* NAV bars */

.color-nav {
    background-color: #5fae1f;
}

.bottom-nav {
  background-color: #5fae1f;
  height: 18%;
}

@media screen and (max-width: 1000px) {
  .bottom-nav {
    height: 30%;
  }
}

/* Home button */

.homeButton {
  background-color: #26B9C7;
  margin-left: 40%; 
  margin-right: 40%;
}

.aboutButton {
  background-color: #26B9C7;
  margin-left: 20%; 
  margin-right: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
}

@media screen and (max-width: 1000px) {
  .homeButton {
    margin-left: 20%; 
    margin-right: 20%
  }
}

/* Offers */

.dashed-border {
  text-align: center;
  border: 5px dashed #26B9C7;
}

.dashed-border-container {
  margin-bottom: 14%;
}

@media screen and (max-width: 1000px) {
  .dashed-border-container {
    margin-bottom: 66%;
  }
}

/* Contact Form */
.contact-form {
  margin-top: 10%;
  margin-left: 26%;
  margin-right: 26%;
}

@media screen and (max-width: 1000px) {
  .contact-form {
    margin-top: 42%;
    margin-bottom: 50%;
  }
}